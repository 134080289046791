import {
  useDisclosure,
  Button,
  Select,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { createNewCategory, updateCategory } from "../../utils/api";
import useStore from "../../utils/store";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import Loading from "../common/ui/Loading";

const Modal = ({ reload, title, update, length, position }) => {
  const [newCategory, setNewCategory] = React.useState({
    position: length + 1,
  });

  const user = useStore((state) => state.user);
  const [updateAction, deleteAction] = useActions();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const newCategoriesHandler = async () => {
    setLoading(true);
    if (
      newCategory.name &&
      newCategory.position &&
      newCategory.type &&
      newCategory.imageType &&
      user?.addCategory
    ) {
      if (!update) {
        const [success, data] = await createNewCategory(newCategory);
        if (success) {
          toast({
            title: "Category Created",
            description: "Category Created Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          reload();
        } else {
          toast({
            title: "Error",
            description: "Error Creating Category",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        await updateAction(newCategory, update._id, updateCategory);
        reload();
      }
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose1();
    setLoading(false);
  };

  useEffect(() => {
    if (update) {
      setNewCategory({
        name: update?.name,
        position: position,
        type: update?.type,
        imageType: update?.imageType,
      });
    }
  }, [update]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Button variant={"solid"} colorScheme={"blue"} onClick={onOpen1}>
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen1}
        onClose={onClose1}
        create={newCategoriesHandler}
        title={update ? "Edit Category" : "Create New Category"}
      >
        <CreateInput
          name={"Name"}
          onChange={(e) => {
            setNewCategory((prev) => ({ ...prev, name: e.target.value }));
          }}
          value={newCategory?.name ? newCategory.name : ""}
        />
        <CreateInput
          name={"Position"}
          type={"number"}
          onChange={(e) => {
            setNewCategory((prev) => ({ ...prev, position: e.target.value }));
          }}
          value={position ? position : newCategory?.position}
        />
        {!update && (
          <>
            <FormLabel htmlFor="type">Type</FormLabel>
            <Select
              placeholder="Select Type"
              onChange={(e) => {
                setNewCategory((prev) => ({ ...prev, type: e.target.value }));
              }}
              value={newCategory?.type ? newCategory.type : ""}
            >
              <option value="Movie">Movie</option>
              <option value="Series">Series</option>
            </Select>
          </>
        )}
        <FormLabel htmlFor="type">Image-Type</FormLabel>
        <Select
          placeholder="Select Image-Type"
          onChange={(e) => {
            setNewCategory((prev) => ({ ...prev, imageType: e.target.value }));
          }}
          value={newCategory?.imageType ? newCategory.imageType : ""}
        >
          <option value="Horizontal">Horizontal</option>
          <option value="Vertical">Vertical</option>
        </Select>
      </CreateModal>
    </>
  );
};

export default Modal;
