import {
  Button,
  Image,
  Select,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CreateModal from "../components/common/Modal/Modal";
import CreateTable from "../components/common/Table/Table";
import Fallback from "../components/common/ui/Fallback";
import { useNavigator } from "../hooks/useNavigator";
import {
  addDatatoRent,
  getAllMovies,
  getAllSeries,
  getAllUserRent,
  getAllUsers,
} from "../utils/api";
const Rent = () => {
  const [rent, setRent] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [updateDetails, setUpdateDetails] = React.useState({});
  const [dataH, setData] = React.useState([]);
  const getallrents = async (id) => {
    if (user?.rent) {
      const [success, data] = await getAllUserRent(id);
      console.log(data);
      if (success) {
        setRent(data);
      }
    }
  };

  const getAllUsersHandler = async () => {
    if (user?.getUser) {
      const [success, data] = await getAllUsers();
      if (success) {
        setUsers(data);
      }
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllUsersHandler();
    } else {
      navigate("/login");
    }
  });
  const toast = useToast();
  const getallMovies = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setData(data);
    }
  };

  const getallSeries = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setData(data);
    }
  };

  const addRented = async () => {
    if (
      updateDetails.type &&
      updateDetails.itemId &&
      updateDetails.userId &&
      user?.rent
    ) {
      const formData = {
        userId: updateDetails.userId,
        type: updateDetails.type,
        [updateDetails.type === "Movie" ? "movieId" : "seriesId"]:
          updateDetails.itemId,
      };

      const [success, data] = await addDatatoRent(formData);
      console.log(data);
      if (success) {
        toast({
          title: "Rented Added",
          description: "Rented Added Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose2();
      } else {
        toast({
          title: "Rented error",
          description: "Rented error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Fill all details",
        description: "Rented error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (updateDetails.type === "Movie") {
      getallMovies();
    }
    if (updateDetails.type === "Series") {
      getallSeries();
    }
  }, [updateDetails.type]);

  return (
    <div>
      {user?.rent ? (
        <CreateTable
          data={users}
          head={["Image", "name", "email", "PhoneNumber", "Rented", "Actions"]}
          rows={(user) => (
            <>
              <Td>
                <Image
                  src={user.image}
                  alt={user.name}
                  sx={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                />
              </Td>

              <Td>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td>{user.phoneNumber}</Td>
              <Td>
                <Button
                  colorScheme={"blue"}
                  onClick={() => {
                    getallrents(user._id);
                    onOpen();
                  }}
                >
                  View
                </Button>
              </Td>
              <Td>
                <Button
                  colorScheme={"blue"}
                  onClick={() => {
                    setUpdateDetails((prev) => ({ ...prev, userId: user._id }));
                    onOpen2();
                  }}
                >
                  Add
                </Button>
              </Td>
            </>
          )}
        />
      ) : (
        <Fallback />
      )}
      <CreateModal table title="Rented" isOpen={isOpen} onClose={onClose}>
        <CreateTable
          data={rent?.rentedContent || []}
          head={["Image", "name", "Expire Date"]}
          rows={(rented) => (
            <>
              <Td>
                <Image
                  src={rented?.item?.horizontalBanner}
                  alt={rented?.item?.name}
                  sx={{ height: "100px", width: "100px" }}
                />
              </Td>
              <Td>{rented?.item?.name}</Td>
              <Td>{rented?.expireDate}</Td>
            </>
          )}
        />
      </CreateModal>
      <CreateModal
        title="Add Rent"
        isOpen={isOpen2}
        onClose={onClose2}
        create={addRented}
      >
        <Select
          placeholder="Select type"
          onChange={(e) => {
            setUpdateDetails((prev) => ({ ...prev, type: e.target.value }));
          }}
        >
          <option value="Movie">Movie</option>
          <option value="Series">Series</option>
        </Select>
        {updateDetails.type && (
          <Select
            placeholder={`Select ${updateDetails?.type}`}
            onChange={(e) => {
              setUpdateDetails((prev) => ({ ...prev, itemId: e.target.value }));
            }}
          >
            {dataH
              .filter((item) => item.isPaid)
              .map((item) => (
                <option value={item._id}>{item.name}</option>
              ))}
          </Select>
        )}
      </CreateModal>
    </div>
  );
};

export default Rent;
