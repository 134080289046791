import { Button, Td } from "@chakra-ui/react";
import React from "react";
import useActions from "../../hooks/useActions";
import { removeFromSubscription } from "../../utils/api";
import CreateTable from "../common/Table/Table";

const TableSub = ({ subscribtions, type, typeId, reload, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [updateAction, deleteAction] = useActions();

  const deleteSubscriptionHandler = async (id) => {
    setLoading(true);
    const data = {
      subscriptionId: id,
      [type === "Movie" ? "movieId" : type === "Series" ? "seriesId" : ""]:
        typeId,
      type: type,
    };

    await deleteAction(data, removeFromSubscription);
    reload();
    onClose();
    setLoading(false);
  };

  return (
    <CreateTable
      head={[
        "Title",
        "Description",
        "Price",
        "Validity",
        "Movies Count",
        "Series Count",
        "Actions",
      ]}
      data={subscribtions}
      rows={(row) => {
        return (
          <>
            <Td>{row.title}</Td>
            <Td>{row.description.slice(0, 30)}...</Td>
            <Td>{row.price}</Td>
            <Td>{row.validity}</Td>
            <Td>{row.moviesCount}</Td>
            <Td>{row.seriesCount}</Td>
            <Td>
              <Button
                colorScheme="red"
                variant="solid"
                isLoading={loading}
                onClick={() => {
                  deleteSubscriptionHandler(row._id);
                }}
              >
                Delete
              </Button>
            </Td>
          </>
        );
      }}
    />
  );
};

export default TableSub;
