import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../../css/Layout.module.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  useEffect(() => {
    onClose();
  }, [location.pathname, onClose]);
  return (
    <div className={styles.container}>
      <nav>
        <Navbar onOpen={onOpen} />
      </nav>
      <aside>
        <Sidebar isOpen={isOpen} onClose={onClose} />
      </aside>
      <main>{children}</main>
    </div>
  );
};

export default Layout;
