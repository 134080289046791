import { Box } from "@chakra-ui/react";
import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const List = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Box shadow={"md"} h="100%">
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "1rem 1.5rem",
            cursor: "pointer",
            borderBottom: "1px solid #eee",
            _hover: {
              bg: "#eee",
              borderRadius: "0.25rem",
            },
          }}
          onClick={() => {
            navigate(item.link);
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            {item.icon}
            {item.name}
          </Box>
          <BiChevronRight />
        </Box>
      ))}
    </Box>
  );
};

export default List;
