import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/common/ui/Layout";
import AddSubcription from "./pages/AddSubcription";
import Admin from "./pages/Admins";
import Banner from "./pages/Banner";
import Categories from "./pages/Categories";
import CreateSeries from "./pages/CreateSeries";
import Genres from "./pages/Genres";
import Home from "./pages/Home";
import Live from "./pages/Live";
import Login from "./pages/Login";
import Movies from "./pages/Movies";
import Notification from "./pages/Notification";
import Podcasts from "./pages/Podcasts";
import Rent from "./pages/Rent";
import Series from "./pages/Series";
import Users from "./pages/Users";
import Support from "./pages/Support";
import Player from "./pages/Player";

function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/support"
          element={
            <Layout>
              <Support />
            </Layout>
          }
        />
        <Route
          path="/player"
          element={
            <Layout>
              <Player />
            </Layout>
          }
        />
        <Route
          path="/podcasts"
          element={
            <Layout>
              <Podcasts />
            </Layout>
          }
        />
        <Route
          path="/series"
          element={
            <Layout>
              <Series />
            </Layout>
          }
        />
        <Route
          path="/categories"
          element={
            <Layout>
              <Categories />
            </Layout>
          }
        />
        <Route
          path="/series/create"
          element={
            <Layout>
              <CreateSeries />
            </Layout>
          }
        />
        <Route
          path="/series/update/:id"
          element={
            <Layout>
              <CreateSeries update={true} />
            </Layout>
          }
        />
        <Route
          path="/banner"
          element={
            <Layout>
              <Banner />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          path="/live"
          element={
            <Layout>
              <Live />
            </Layout>
          }
        />
        <Route
          path="/admins"
          element={
            <Layout>
              <Admin />
            </Layout>
          }
        />
        <Route
          path="/subscribtion"
          element={
            <Layout>
              <AddSubcription />
            </Layout>
          }
        />
        <Route
          path="/movies"
          element={
            <Layout>
              <Movies />
            </Layout>
          }
        />
        <Route
          path="/genres"
          element={
            <Layout>
              <Genres />
            </Layout>
          }
        />
        <Route
          path="/notification"
          element={
            <Layout>
              <Notification />
            </Layout>
          }
        />
        <Route
          path="/rent"
          element={
            <Layout>
              <Rent />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
