import { Box, Button, Image, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/podcasts/Modal";
import Table from "../components/podcasts/Table";
import useActions from "../hooks/useActions";
import { useNavigator } from "../hooks/useNavigator";
import { createNewSeries, updateSeries } from "../utils/api";

const CreateSeries = ({ update }) => {
  const { id } = useParams();
  const { state } = useLocation();
  const [updateAction, deleteAction] = useActions();
  const [newSeries, setNewSeries] = React.useState({});
  const toast = useToast();
  const [podcasts, setPodcasts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const seriesDetailsHanlder = (res) => {
    setNewSeries(res);
  };

  useEffect(() => {
    if (update) {
      setNewSeries({
        name: state?.name,
        description: state?.description,
        verticalBanner: state?.verticalBanner,
        horizontalBanner: state?.horizontalBanner,
        duration: state?.duration,
        language: state?.language,
        rating: state?.rating,
        ageLimit: state?.ageLimit,
        genres: state?.genres,
        cast: state?.cast,
        urlType: state?.urlType,
        isPaid: state?.isPaid,
        isFree: state?.isFree,
        isPremium: state?.isPremium,
        trailer: state?.trailer,
        trailerType: state?.trailerType,
      });
      setPodcasts(state?.episodes);
    }
  }, [
    state?.ageLimit,
    state?.cast,
    state?.description,
    state?.duration,
    state?.episodes,
    state?.genres,
    state?.horizontalBanner,
    state?.language,
    state?.name,
    state?.rating,
    state?.verticalBanner,
    state?.urlType,
    state?.isPaid,
    state?.isFree,
    state?.isPremium,
    state?.trailer,
    state?.trailerType,

    update,
  ]);

  const [navigate, user] = useNavigator(() => {
    if (user) {
    } else {
      navigate("/login");
    }
  });

  const CreateSeriesHandler = async () => {
    if (
      newSeries.name &&
      newSeries.description &&
      newSeries.verticalBanner &&
      newSeries.horizontalBanner &&
      newSeries.duration &&
      newSeries.language &&
      newSeries.rating &&
      newSeries.ageLimit &&
      newSeries.genres.length > 0 &&
      podcasts.length > 0 &&
      user?.addSeries
    ) {
      const data = {
        ...newSeries,
        episodes: podcasts,
      };
      setLoading(true);
      if (!update) {
        const [status, res] = await createNewSeries(data);
        if (status) {
          toast({
            title: "Series Created",
            description: "Series Created Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "Error Creating Series",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        await updateAction(data, id, updateSeries);
      }
      navigate("/series");
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  const updatePodcast = (data, id) => {
    const index = podcasts.findIndex((podcast) => podcast._id === id);
    setPodcasts([
      ...podcasts.slice(0, index),
      data,
      ...podcasts.slice(index + 1),
    ]);
  };

  const deletePodcast = (id) => {
    const index = podcasts.findIndex((podcast) => podcast._id === id);
    setPodcasts([...podcasts.slice(0, index), ...podcasts.slice(index + 1)]);
  };
  return (
    <Box
      sx={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}
    >
      <Box
        sx={{
          display: "flex",

          gap: 10,
        }}
      >
        <Box
          sx={{
            width: "25rem",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            border: "1px solid #e2e8f0",
            padding: 5,
            rounded: 5,
          }}
        >
          <Modal
            title="Enter Series Details"
            save={seriesDetailsHanlder}
            series
            update={
              update
                ? {
                    name: state.name,
                    description: state.description,
                    verticalBanner: state.verticalBanner,
                    horizontalBanner: state.horizontalBanner,
                    urlType: state.urlType,
                    duration: state.duration,
                    language: state.language,
                    rating: state.rating,
                    ageLimit: state.ageLimit,
                    genres: state.genres,
                    cast: state.cast,
                    accessTime: state.accessTime,
                    price: state.price,
                    trailer: state.trailer,
                    isPaid: state.isPaid,
                    isPremium: state.isPremium,
                    isFree: state.isFree,
                    trailerType: state.trailerType,
                  }
                : null
            }
          />
          <Text
            fontSize="2xl"
            textDecoration={"underline"}
            textUnderlineOffset={"0.2rem"}
          >
            Series Details
          </Text>
          {newSeries && (
            <>
              <Text>Name: {newSeries?.name}</Text>
              <Text>Description: {newSeries?.description}</Text>
              <Image src={newSeries?.verticalBanner} rounded="xl" />
              <Image src={newSeries?.horizontalBanner} rounded="xl" />
            </>
          )}
        </Box>
        <Box
          rounded={"lg"}
          sx={{
            width: "50%",
            padding: "2rem",
            border: "1px solid #e2e8f0",
            display: "flex",
            flexDirection: "column",
            gap: 5,
          }}
        >
          <Modal
            title="Add Episodes"
            episeries
            save={(res) => {
              setPodcasts((prev) => [
                ...prev,
                {
                  name: res.name,
                  description: res.description,
                  duration: " ",
                  language: " ",
                  rating: " ",
                  ageLimit: " ",
                  genres: [],
                  cast: [],
                  url: res.url,
                  urlType: res.urlType,
                  horizontalBanner: res.horizontalBanner,
                  verticalBanner: res.verticalBanner,
                },
              ]);
            }}
          />
          <Table
            allPodcasts={podcasts}
            episeries
            actions
            series
            updatedSeriesPodcast={updatePodcast}
            deleteSeriesPodcast={deletePodcast}
          />
        </Box>
      </Box>
      <Button colorScheme="blue" variant="solid" onClick={CreateSeriesHandler}>
        {update ? "Edit Series" : "Create Series"}
      </Button>
    </Box>
  );
};

export default CreateSeries;
