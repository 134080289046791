import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
const CreateModal = ({
  isOpen,
  onClose,
  children,
  title,
  create,
  table,
  button1Color,
  button1,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={table ? "4xl" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          {children}
        </ModalBody>

        {!table && (
          <ModalFooter>
            <Button
              colorScheme={button1Color ? button1Color : "blue"}
              mr={3}
              onClick={create}
            >
              {button1 ? button1 : "Save"}
            </Button>

            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
