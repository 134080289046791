import {
  Box,
  Button,
  Image,
  Select,
  Td,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CreateTable from "../components/common/Table/Table";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/users/Modal";
import { useNavigator } from "../hooks/useNavigator";
import {
  deleteUser,
  getAllSubscribtions,
  getAllUsers,
  getUserNotifications,
  getUserSubscriptions,
  updateUserImage,
  updateUserName,
  updateUserSubscription,
} from "../utils/api";
import useActions from "../hooks/useActions";
import CreateInput from "../components/common/TextInputs/Input";
import CreateModal from "../components/common/Modal/Modal";
import File from "../components/common/TextInputs/File";
import Fallback from "../components/common/ui/Fallback";
import DeleteModal from "../components/common/Modal/DeleteModal";
const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [newUser, setNewUser] = React.useState({});
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  const {
    isOpen: isOpenModal3,
    onOpen: onOpenModal3,
    onClose: onCloseModal3,
  } = useDisclosure();

  const {
    isOpen: isOpenModal4,
    onOpen: onOpenModal4,
    onClose: onCloseModal4,
  } = useDisclosure();
  const [notification, setNotification] = React.useState(false);
  const getNotification = async (id) => {
    setLoading(true);
    const [success, data] = await getUserNotifications(id);
    console.log(data);
    if (success) {
      setNotification(data);
      onOpenModal1();
    }

    setLoading(false);
  };
  const [subscription, setSubscription] = React.useState({});
  const [allSubscriptions, setAllSubscriptions] = React.useState([]);
  const [userId, setUserId] = React.useState({});
  const getAllUsersHandler = async () => {
    if (user?.getUser) {
      const [success, data] = await getAllUsers();
      if (success) {
        setUsers(data);
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllUsersHandler();
    } else {
      navigate("/login");
    }
  });
  const [updateAction, deleteAction] = useActions();

  const getUserSubscriptionsHandler = async (id) => {
    const [success, data] = await getUserSubscriptions(id);
    if (success) {
      setSubscription(data);
    }
  };

  const getAllSubsHandler = async () => {
    const [success, data] = await getAllSubscribtions();
    if (success) {
      setAllSubscriptions(data);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {user?.addUser && (
        <Modal title={" Create User"} reload={getAllUsersHandler} />
      )}
      {user?.getUser ? (
        <CreateTable
          searchby={["name", "language", "ageLimit", "rating"]}
          pagging={true}
          data={users}
          head={[
            "Profile",
            "Name",
            "Email",
            "Phone-Number",
            "D.O.B",
            "Subscription Actions",
            "Actions",
          ]}
          rows={(row) => {
            console.log(row);
            return (
              <>
                <Td>
                  <Image src={row.image} rounded="full" boxSize="50px" />
                </Td>
                <Td>{row.name}</Td>
                <Td>{row.email}</Td>
                <Td>{row.phoneNumber}</Td>
                <Td>{row.dob}</Td>
                <Td>
                  <Box
                    sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                  >
                    <Button
                      colorScheme={"blue"}
                      sx={{
                        width: "100%",
                      }}
                      variant="solid"
                      onClick={() => {
                        getNotification(row._id);
                      }}
                    >
                      Notifications
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        getUserSubscriptionsHandler(row._id);
                        onOpenModal3();
                      }}
                    >
                      Subscriptions
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        getAllSubsHandler();
                        setUserId((prev) => ({ ...prev, userId: row._id }));
                        onOpenModal4();
                      }}
                    >
                      Add Subscription
                    </Button>
                  </Box>
                </Td>
                <Td>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {user?.updateUser && (
                      <>
                        <Modal
                          title={" Update User"}
                          reload={getAllUsersHandler}
                          updateData={row}
                          update={{
                            name: row.name,
                            email: row.email,
                            phoneNumber: row.phoneNumber,
                            image: row.image,
                          }}
                        />
                      </>
                    )}
                    {user?.deleteUser && (
                      <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteUser);
                          getAllUsersHandler();
                          setLoading(false);
                        }}
                      />
                    )}
                  </Box>
                </Td>
              </>
            );
          }}
        />
      ) : (
        <Fallback />
      )}

      <CreateModal
        title={"Edit Name"}
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        create={async () => {
          setLoading(true);
          await updateAction(
            { name: newUser.name },
            newUser.id,
            updateUserName
          );
          setNewUser({});
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <CreateInput
          name="Name"
          onChange={(e) => {
            setNewUser((prev) => ({ ...prev, name: e.target.value }));
          }}
        />
      </CreateModal>

      <CreateModal
        title={"Edit Image"}
        isOpen={isOpenModal2}
        onClose={onCloseModal2}
        create={async () => {
          setLoading(true);
          const formData = new FormData();
          formData.append("image", newUser.image);
          await updateAction(formData, newUser.id, updateUserImage);
          setNewUser({});
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <File
          onChange={(e) =>
            setNewUser((prev) => ({ ...prev, image: e.target.files[0] }))
          }
          name="New Image"
        />
      </CreateModal>

      <CreateModal
        title={"Subscriptions"}
        isOpen={isOpenModal3}
        onClose={onCloseModal3}
        table
      >
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Text sx={{ fontWeight: "700" }} size="lg">
            History
          </Text>
          {subscription[0]?.history?.map((sub, index) => (
            <Text sx={{ fontWeight: "600" }}>
              {index} : {sub}
            </Text>
          ))}
        </Box>
      </CreateModal>

      <CreateModal
        title={"Add Subscription"}
        isOpen={isOpenModal4}
        onClose={onCloseModal4}
        create={async () => {
          setLoading(true);
          await updateAction(userId, "id", updateUserSubscription);
          setUserId({});
          onCloseModal4();
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <Select
          placeholder="select subscription"
          onChange={(e) => {
            setUserId((prev) => ({ ...prev, subscriptionId: e.target.value }));
          }}
        >
          {allSubscriptions?.map((sub) => (
            <option value={sub._id}>{sub.title}</option>
          ))}
        </Select>
      </CreateModal>
      <CreateModal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        title={"Notification"}
        table
      >
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <CreateTable
            data={notification}
            head={["title", "description", "date", "image", "read"]}
            rows={(row) => (
              <>
                <Td>{row?.title}</Td>
                <Td>{row?.description.slice(0, 22)}</Td>
                <Td>{row?.date}</Td>
                <Td>
                  <Image src={row?.image} rounded="full" boxSize="50px" />
                </Td>
                <Td>{row?.read ? "true" : "false"}</Td>
              </>
            )}
          />
        </Box>
      </CreateModal>
    </Box>
  );
};

export default Users;
