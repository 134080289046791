import {
  Box,
  Button,
  Link,
  Select,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DeleteModal from "../components/common/Modal/DeleteModal";
import CreateModal from "../components/common/Modal/Modal";
import CreateTable from "../components/common/Table/Table";
import CreateInput from "../components/common/TextInputs/Input";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import useActions from "../hooks/useActions";
import { useNavigator } from "../hooks/useNavigator";
import {
  createNewBanner,
  deleteAdvertisement,
  deleteBanner,
  getAdVertisement,
  getAllBanners,
  getAllMovies,
  getAllSeries,
  updateBanner,
} from "../utils/api";

const Support = () => {
  const [banner, setBanner] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [newBanner, setNewBanner] = React.useState({});
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log("banner", banner);
  const [loading, setLoading] = React.useState(true);
  const [updateAction, deleteAction] = useActions();
  const [itemData, setItemData] = React.useState([]);
  const [itemType, setItemType] = React.useState("");

  const bannersHandler = async () => {
    if (user?.getBanner) {
      const [success, data] = await getAdVertisement();
      if (success) {
        setBanner(data);
      }
    }
    setLoading(false);
  };

  const [navigate, user] = useNavigator(bannersHandler);
  console.log("banner", user);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {user?.getBanner ? (
        <CreateTable
          data={banner}
          head={[
            "Name",
            "Phone Number",
            "Email",
            "Message",
            "Advertising",
            "File",
          ]}
          rows={(row) => {
            return (
              <>
                <Td>{row?.name}</Td>
                <Td>{row?.phoneNumber}</Td>
                <Td>{row?.email}</Td>
                <Td>{row?.message}</Td>
                <Td>{row?.advertising}</Td>
                <Td>
                  <Link href={row?.adFile} isExternal>
                    {row?.adFile ? "Link" : ""}
                  </Link>
                </Td>
                <Td>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                    }}
                  >
                    {user?.deleteBanner && (
                      <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteAdvertisement);
                          setLoading(false);
                          bannersHandler();
                        }}
                      />
                    )}
                  </Box>
                </Td>
              </>
            );
          }}
        />
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Support;
