import { Button, Image, Td, useDisclosure, Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useActions from "../../hooks/useActions";
import {
  deleteSeries,
  enableDisableSeries,
  updateSeries,
} from "../../utils/api";
import useStore from "../../utils/store";
import Buttons from "../common/Actions/Buttons";
import DeleteModal from "../common/Modal/DeleteModal";
import CreateModal from "../common/Modal/Modal";
import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";
import Table from "../podcasts/Table";
import ModalSub from "../subscription/Modal";
import TableSub from "../subscription/Table";

const TableSeries = ({ series, actions, reload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [data2, setData2] = React.useState([]);
  const [isloading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const user = useStore((state) => state.user);
  const actionsI =
    actions && (user?.updateSeries || user?.deleteSeries) ? "Actions" : false;
  const navigate = useNavigate();
  const [updateAction, deleteAction] = useActions();
  if (isloading) {
    return <Loading />;
  }
  return (
    <>
      <CreateTable
        searchby={["name", "language", "ageLimit", "rating"]}
        pagging={true}
        head={[
          actionsI,
          "Name",

          "Description",
          "Subscription",
          "Horizontal-Banner",
          "Vertical-Banner",
          "Duration",
          "Age-Limit",
          "Rating",
          "Episodes",
          "cast",
          "Genres",
        ]}
        rows={(row) => (
          <>
            {actions && (user?.updateSeries || user?.deleteSeries) && (
              <Td>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                  }}
                >
                  <ModalSub item={row} reload={reload} series />
                  {user?.deleteSeries && (
                    <DeleteModal
                      onClick={async () => {
                        setLoading(true);
                        await deleteAction(row._id, deleteSeries);
                        reload();
                        setLoading(false);
                      }}
                    />
                  )}
                  {user?.updateSeries && (
                    <>
                      <Button
                        colorScheme={"blue"}
                        onClick={() =>
                          navigate(`/series/update/${row._id}`, {
                            state: row,
                          })
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        colorScheme={row.isEnable ? "red" : "green"}
                        variant="solid"
                        onClick={async () => {
                          await updateAction(
                            {
                              isEnable: !row.isEnable,
                            },
                            row._id,
                            enableDisableSeries
                          );

                          reload();
                        }}
                        sx={{
                          marginTop: "10px",
                          width: "100%",
                        }}
                      >
                        {row.isEnable ? "Disable" : "Enable"}
                      </Button>
                    </>
                  )}
                </Box>
              </Td>
            )}
            <Td>{row.name}</Td>
            <Td>{row.description.slice(0, 25)}...</Td>{" "}
            <Td>
              <Button
                colorScheme={"blue"}
                onClick={() => {
                  onOpen2();
                  setData2(row.subscriptions);
                }}
              >
                View
              </Button>
            </Td>
            <Td>
              <Image
                src={row.horizontalBanner}
                alt="horizontal-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>
              <Image
                src={row.verticalBanner}
                alt="vertical-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>{row.duration}</Td>
            <Td>{row.ageLimit}</Td>
            <Td>{row.rating}</Td>
            <Td>
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={() => {
                  setData(series.filter((item) => item._id === row._id)[0]);
                  onOpen();
                }}
              >
                View
              </Button>
            </Td>
            <Td>
              {row.cast.map((item, index) => {
                return <p key={index}>{item.name}</p>;
              })}
            </Td>
            <Td>
              {row.genres.map((item, index) => {
                return <p key={index}>{item.name}</p>;
              })}
            </Td>
          </>
        )}
        data={series}
      />{" "}
      <CreateModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setData({});
        }}
        table
        title="Episodes"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Table allPodcasts={data?.episodes} />
        </Box>
      </CreateModal>
      <CreateModal
        isOpen={isOpen2}
        onClose={() => {
          onClose2();
          setData2([]);
        }}
        title={"View Subscribtions"}
        table
      >
        <TableSub subscribtions={data2} />
      </CreateModal>
    </>
  );
};

export default TableSeries;
