import { Box } from "@chakra-ui/react";
import React from "react";
import useStore from "../../../utils/store";
import Modal from "../../podcasts/Modal";
import DeleteModal from "../Modal/DeleteModal";

const Buttons = ({ item, update, deletePodcast, episeries }) => {
  const user = useStore((state) => state.user);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        marginTop: "1rem",
      }}
    >
      {user?.updateMovie && (
        <Modal
          title={"Edit"}
          update={item}
          color="green"
          save={update}
          episeries={episeries}
        />
      )}
      {user?.deleteMovie && (
        <DeleteModal
          onClick={() => {
            deletePodcast(item._id);
          }}
        />
      )}
    </Box>
  );
};

export default Buttons;
