import { useState } from "react";
import { Box, useToast, Button, Input, Text } from "@chakra-ui/react";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import Fallback from "../components/common/ui/Fallback";
import CreateInput from "../components/common/TextInputs/Input";
import { addNotification, getAllUsers } from "../utils/api";
import CheckboxInput from "../components/common/TextInputs/Checkbox";

const Notification = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const AllUsers = async () => {
    setLoading(true);
    const [success, data] = await getAllUsers();
    if (success) {
      setUsers(data);
    }
    setLoading(false);
  };

  const [navigate, user] = useNavigator(() => {
    if (user) {
      AllUsers();
    } else {
      navigate("/login");
    }
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const addNewNotification = async () => {
    setLoading(true);
    if (title.length < 1) {
      toast({
        title: "Error",
        description: "Please Enter Title",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    if (description.length < 1) {
      toast({
        title: "Error",
        description: "Please Enter Description",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    if (selectedUsers.length < 1) {
      toast({
        title: "Error",
        description: "Please Select Users",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("image", image);
    formdata.append("users", JSON.stringify(selectedUsers));

    const response = await addNotification(formdata);
    if (response[0]) {
      toast({
        title: "Success",
        description: "Notification Added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setSelectedUsers([]);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <center>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          padding: "1rem",
          maxWidth: "50%",
        }}
      >
        {user.notification ? (
          <div>
            <br />
            <CreateInput
              name={"Title"}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <br />
            <CreateInput
              name={"Description"}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <br />
            <Text
              fontWeight="semibold"
              color="black"
              textAlign={"left"}
              marginBottom={".5rem"}
            >
              Select Image
            </Text>
            <Input type="file" onChange={(e) => setImage(e.target.files[0])} />
            <br />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: ".5rem",
                marginBottom: ".5rem",
                marginTop: ".5rem",
              }}
            >
              <h1
                style={{
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Select Users
              </h1>
              <CheckboxInput
                name={"Select All"}
                notification
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedUsers(users.map((user) => user._id));
                  } else {
                    setSelectedUsers([]);
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                alignItems: "left",
              }}
            >
              {users.map((user) => {
                return (
                  <CheckboxInput
                    name={user.name}
                    notification
                    isChecked={selectedUsers.includes(user._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedUsers((prev) => [...prev, user._id]);
                      } else {
                        setSelectedUsers((prev) =>
                          prev.filter((id) => id !== user._id)
                        );
                      }
                    }}
                  />
                );
              })}{" "}
            </Box>
            <br />
            <Button onClick={addNewNotification} colorScheme={"blue"}>
              Send Notification
            </Button>
          </div>
        ) : (
          <Fallback />
        )}
      </Box>
    </center>
  );
};

export default Notification;
