import { useToast } from "@chakra-ui/react";
import React from "react";

const useActions = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const updateActionHandler = async (newdata, id, action) => {
    if (newdata && id) {
      setLoading(true);
      const [success, res] = await action(newdata, id);
      if (success) {
        toast({
          title: " Updated",
          description: " Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Error Updating",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };

  const deletePodcastHandler2 = async (id, action) => {
    const [success, res] = await action(id);
    if (success) {
      toast({
        title: " Deleted",
        description: " Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error Deleting ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return [updateActionHandler, deletePodcastHandler2];
};

export default useActions;
