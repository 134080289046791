import React from "react";
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import CheckboxInput from "../common/TextInputs/Checkbox";
const Modal = ({ save, title, update, color }) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [newPodcast, setNewPodcast] = React.useState({
    email: "",
    password: "",
    getMovies: false,
    updateMovie: false,
    deleteMovie: false,
    addMovies: false,
    getSeries: false,
    updateSeries: false,
    deleteSeries: false,
    addSeries: false,
    getCategory: false,
    updateCategory: false,
    deleteCategory: false,
    addCategory: false,
    getBanner: false,
    updateBanner: false,
    deleteBanner: false,
    addBanner: false,
    getUser: false,
    updateUser: false,
    deleteUser: false,
    addUser: false,
    addAdmin: false,
    getAdmin: false,
    addSubscriprion: false,
    updateSubscription: false,
    deleteSubscription: false,
    notification: false,
    rent: false,
    addMovieToSubscription: false,
    addSeriesToSubscription: false,
  });

  const saveHandler = async () => {
    if (newPodcast.email && newPodcast.password) {
      const newPodcastData = {
        ...newPodcast,
      };
      await save(newPodcastData);
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const clickCheckbox = (e, name) => {
    const { checked } = e.target;
    setNewPodcast((prev) => ({ ...prev, [name]: checked }));
  };

  const checkBoxes = [
    {
      label: "Rent",
      name: "rent",
    },
    {
      label: "View Movies",
      name: "getMovies",
    },
    {
      label: "Add Movies",
      name: "addMovies",
    },
    {
      label: "Edit Movie",
      name: "updateMovie",
    },
    {
      label: "Delete Movie",
      name: "deleteMovie",
    },
    {
      label: "View Series",
      name: "getSeries",
    },
    {
      label: "Add Series",
      name: "addSeries",
    },
    {
      label: "Edit Series",
      name: "updateSeries",
    },
    {
      label: "Delete Series",
      name: "deleteSeries",
    },
    {
      label: "View Category",
      name: "getCategory",
    },
    {
      label: "Add Category",
      name: "addCategory",
    },
    {
      label: "Edit Category",
      name: "updateCategory",
    },
    {
      label: "Delete Category",
      name: "deleteCategory",
    },
    {
      label: "View Banner",
      name: "getBanner",
    },
    {
      label: "Add Banner",
      name: "addBanner",
    },
    {
      label: "Edit Banner",
      name: "updateBanner",
    },
    {
      label: "Delete Banner",
      name: "deleteBanner",
    },
    {
      label: "View User",
      name: "getUser",
    },
    {
      label: "Add User",
      name: "addUser",
    },
    {
      label: "Edit User",
      name: "updateUser",
    },
    {
      label: "Delete User",
      name: "deleteUser",
    },

    {
      label: "View Admin",
      name: "getAdmin",
    },
    {
      label: "Add Admin",
      name: "addAdmin",
    },
    {
      label: "View Subscription",
      name: "getSubscription",
    },
    {
      label: "Add Subscription",
      name: "addSubscription",
    },
    {
      label: "Edit Subscription",
      name: "updateSubscription",
    },
    {
      label: "Delete Subscription",
      name: "deleteSubscription",
    },
    {
      label: "Notification",
      name: "notification",
    },
    {
      label: "Add Movie to Subscription",
      name: "addMovieToSubscription",
    },
    {
      label: "Add Series to Subscription",
      name: "addSeriesToSubscription",
    },
  ];
  return (
    <Box
      sx={{
        gap: 3,
        width: "100%",
      }}
    >
      <Button
        onClick={onOpen}
        leftIcon={!color && <BsPlus />}
        colorScheme={color ? color : "blue"}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={saveHandler}
      >
        <CreateInput
          name="Email"
          onChange={(e) => {
            setNewPodcast((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
        <CreateInput
          name="Password"
          onChange={(e) => {
            setNewPodcast((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        <Box
          fontSize="md"
          fontWeight={"bold"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          Privages{" "}
          <CheckboxInput
            name={"all"}
            onChange={(e) => {
              const { checked } = e.target;
              checkBoxes.map((item) => {
                setNewPodcast((prev) => ({ ...prev, [item.name]: checked }));
              });
            }}
          />
        </Box>
        {checkBoxes.map((item) => (
          <CheckboxInput
            key={item.name}
            name={item.label}
            onChange={(e) => clickCheckbox(e, item.name)}
            isChecked={newPodcast[item.name]}
          />
        ))}
      </CreateModal>
    </Box>
  );
};

export default Modal;
