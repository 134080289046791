import { Box, Button, Td, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import useActions from "../../hooks/useActions";
import Select from "react-select";
import {
  addMovieToCategory,
  addSeriesToCategory,
  deleteCategory,
  removeMoviefromCategory,
  removeSeriesfromCategory,
} from "../../utils/api";
import useStore from "../../utils/store";
import DeleteModal from "../common/Modal/DeleteModal";
import CreateModal from "../common/Modal/Modal";
import CreateTable from "../common/Table/Table";
import CreateInput from "../common/TextInputs/Input";
import Loading from "../common/ui/Loading";
import Table from "../podcasts/Table";
import TableSeries from "../series/Table";
import Modal from "./Modal";
import Position from "./Position";

const TableCategories = ({ categories, movie, series, reload }) => {
  const [loading, setLoading] = React.useState(false);
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  const user = useStore((state) => state.user);
  const [add, setAdd] = React.useState({
    name: "",
    data: {},
  });
  const [lists, setLists] = React.useState([]);
  const [listsItem, setListsItem] = React.useState([]);
  const [newListId, setNewListId] = React.useState({});
  const [remove, setRemove] = React.useState({});
  const [removeData, setRemoveData] = React.useState({});
  const [updateAction, deleteAction] = useActions();
  const toast = useToast();
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <CreateTable
        data={categories}
        head={["Name", "Position", "type", "Image-Type", "Action"]}
        rows={(item) => (
          <>
            <Td>{item.name}</Td>
            <Td>{categories.findIndex((i) => i._id === item._id) + 1}</Td>
            <Td>{item.type}</Td>
            <Td>{item.imageType}</Td>
            <Td>
              <Button
                variant={"solid"}
                colorScheme={"blue"}
                onClick={() => {
                  setLists(item.content);
                  setListsItem(
                    item.content.map((item) => {
                      return item.item;
                    })
                  );
                  onOpen3();
                }}
              >
                View
              </Button>
            </Td>
            <Td
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                {
                  user?.updateCategory && (
                    // (item.type === "Movie" ? (
                    <>
                      <Button
                        variant={"solid"}
                        colorScheme={"blue"}
                        onClick={() => {
                          setAdd({
                            name: "Add Movie",
                            data: item,
                          });
                          onOpen2();
                        }}
                      >
                        Add Movie
                      </Button>
                      <Button
                        variant={"solid"}
                        colorScheme={"red"}
                        onClick={() => {
                          setRemove({
                            name: "Movie",
                            data: item,
                          });
                          onOpen4();
                        }}
                      >
                        Remove Movie
                      </Button>

                      <Button
                        variant={"solid"}
                        colorScheme={"blue"}
                        onClick={() => {
                          setAdd({
                            name: "Add Series",
                            data: item,
                          });
                          onOpen2();
                        }}
                      >
                        Add Series
                      </Button>
                      <Button
                        variant={"solid"}
                        colorScheme={"red"}
                        onClick={() => {
                          setRemove({
                            name: "Series",
                            data: item,
                          });
                          onOpen4();
                        }}
                      >
                        Remove Series
                      </Button>
                      <Position
                        item={item}
                        reload={reload}
                        setLoading={setLoading}
                      />
                    </>
                  )
                  // ))}
                }
                {user?.deleteCategory && (
                  <DeleteModal
                    onClick={async () => {
                      setLoading(true);
                      await deleteAction(item._id, deleteCategory);
                      setLoading(false);
                      reload();
                    }}
                  />
                )}
                {user?.updateCategory && (
                  <Modal
                    update={item}
                    title="Edit Category"
                    reload={reload}
                    position={
                      categories.findIndex((i) => i._id === item._id) + 1
                    }
                  />
                )}
              </Box>
            </Td>
          </>
        )}
      />
      <CreateModal
        isOpen={isOpen2}
        onClose={() => {
          setAdd({
            name: "",
            data: {},
          });
          setNewListId({});
          onClose2();
        }}
        title={add.name}
        create={async () => {
          setLoading(true);

          const formData = new FormData();
          formData.append("categoryId", add.data._id);
          formData.append(
            "position",
            newListId.position
              ? newListId.position
              : add?.data?.content?.length + 1
          );
          if (add.name === "Add Movie") {
            formData.append("movieId", newListId.movieId);
            const [success, res] = await addMovieToCategory(formData);
            if (success) {
              toast({
                title: "Movie Added",
                description: "Movie Added Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Adding Movie",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          } else {
            formData.append("seriesId", newListId.seriesId);

            const [success, res] = await addSeriesToCategory(formData);
            if (success) {
              toast({
                title: "Series Added",
                description: "Series Added Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Adding Series",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
          setLoading(false);
        }}
      >
        <Select
          placeholder={
            add.name === "Add Movie" ? "Select Movie" : "Select Series"
          }
          onChange={(e) => {
            setNewListId((prev) => ({
              ...prev,
              [add.name === "Add Movie" ? "movieId" : "seriesId"]: e.value,
            }));
          }}
          options={
            add.name === "Add Movie"
              ? movie
                  ?.filter(
                    (item2) =>
                      add?.data?.content?.findIndex(
                        (item3) => item3.item._id === item2._id
                      ) === -1
                  )
                  ?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))
              : series
                  ?.filter(
                    (item2) =>
                      add?.data?.content?.findIndex(
                        (item3) => item3.item._id === item2._id
                      ) === -1
                  )
                  ?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))
          }
        />
        {/* {add.name === "Add Movie"
            ? movie?.map((item) => (
                <option value={item._id}>{item.name}</option>
              ))
            : series?.map((item) => (
                <option value={item._id}>{item.name}</option>
              ))} */}

        <CreateInput
          type={"number"}
          name={"Position"}
          value={
            newListId.position
              ? newListId.position
              : add?.data?.content?.length + 1
          }
          onChange={(e) => {
            setNewListId((prev) => ({
              ...prev,
              position: e.target.value,
            }));
          }}
        />
      </CreateModal>
      <CreateModal
        isOpen={isOpen3}
        onClose={() => {
          setListsItem([]);
          onClose3();
        }}
        title={"Lists"}
        table
      >
        {lists[0]?.kind === "series" ? (
          <TableSeries series={listsItem} categories />
        ) : (
          <Table allPodcasts={listsItem} categories />
        )}
      </CreateModal>

      <CreateModal
        isOpen={isOpen4}
        onClose={() => {
          onClose4();
        }}
        title={"Remove"}
        create={async () => {
          setLoading(true);
          if (remove.name === "Movie") {
            const [success, res] = await removeMoviefromCategory(removeData);
            if (success) {
              toast({
                title: "Movie Removed",
                description: "Movie Removed Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Removing Movie",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }

          if (remove.name === "Series") {
            const [success, res] = await removeSeriesfromCategory(removeData);
            if (success) {
              toast({
                title: "Series Removed",
                description: "Series Removed Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Removing Series",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
          setLoading(false);
        }}
      >
        <Select
          placeholder={"Select Data"}
          // onChange={(e) => {
          //   console.log(remove);
          //   setRemoveData((prev) => ({
          //     [remove.name === "Movie" ? "movieId" : "seriesId"]:
          //       e.target.value,

          //     categoryId: remove.data._id,
          //   }));
          // }}
          onChange={(e) => {
            setRemoveData({
              [remove.name === "Movie" ? "movieId" : "seriesId"]: e.value,
              categoryId: remove.data._id,
            });
          }}
          options={
            remove.name === "Movie"
              ? remove?.data?.content
                  ?.filter((item) => item.kind === "movie")
                  .map((item) => ({
                    value: item.item._id,
                    label: item.item.name,
                  }))
              : remove?.data?.content
                  ?.filter((item) => item.kind === "series")
                  .map((item) => ({
                    value: item.item._id,
                    label: item.item.name,
                  }))
          }
        />
      </CreateModal>
    </>
  );
};

export default TableCategories;
