import { Box, Button, Image, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import File from "../common/TextInputs/File";
import { addUser, updateUser } from "../../utils/api";
import Loading from "../common/ui/Loading";
const Modal = ({ title, reload, update, updateData }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    image: "",
    dob: "",
  });
  console.log(user.phoneNumber);
  const createUserHandler = async () => {
    if (!update) {
      if (user?.phoneNumber.length !== 10) {
        toast({
          title: "Error",
          description: "Phone number must be 10 digits",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
    } else {
    }

    const formData = new FormData();

    console.log(user);

    formData.append("image", user.image ? user.image : "");
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("name", user.name);
    formData.append("dob", user.dob);
    setIsLoading(true);
    if (!update) {
      const [success, res] = await addUser(formData);
      console.log(res);
      if (success) {
        toast({
          title: "User Created",
          description: "User has been created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
      } else {
        toast({
          title: "Error",
          description: "Error creating user",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      const [success, res] = await updateUser(formData, updateData._id);
      console.log(success, res);
      if (success) {
        toast({
          title: "User Updated",
          description: "User has been updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
      } else {
        toast({
          title: "Error",
          description: "Error updating user",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setUser({
      name: "",
      email: "",
      phoneNumber: "",
      image: "",
    });
    onClose();
    setIsLoading(false);
  };
  useEffect(() => {
    if (update) {
      setUser(update);
    }
  }, [update]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Button
        colorScheme="blue"
        variant="solid"
        onClick={onOpen}
        sx={{
          width: "100%",
        }}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={createUserHandler}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CreateInput
            name="Name"
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
            }}
            value={user?.name}
          />

          <CreateInput
            name="Email"
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            type="email"
            value={user.email}
          />
          <CreateInput
            name="Phone Number"
            onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
            type="text"
            value={user.phoneNumber}
          />

          {!update && (
            <CreateInput
              name="D.O.B"
              onChange={(e) => setUser({ ...user, dob: e.target.value })}
              type="date"
              value={user.dob}
            />
          )}
          <File
            name={"Profile Pick"}
            onChange={(e) => setUser({ ...user, image: e.target.files[0] })}
          />

          {update && (
            <Image
              src={user?.image}
              sx={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          )}
        </Box>
      </CreateModal>
    </Box>
  );
};

export default Modal;
